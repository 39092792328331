<template>
  <div :class="['column mural-field', classWrapper]">
    <label class="col-auto column">
      <div class="col-auto">
        <div class="row">
          <div :class="['label', classLabel, hasError ? 'has-error' : 'is-normal']">
            {{ title }}
          </div>
        </div>
      </div>

      <template v-if="!$scopedSlots['after-label']">
        <div
          @click="handleClick"
          :class="['col-auto max-width', hasError ? 'has-error' : 'is-normal']"
        >
          <slot />
        </div>
      </template>
    </label>

    <template v-if="$scopedSlots['after-label']">
      <div
        @click="handleClick"
        :class="['col-auto max-width', hasError ? 'has-error' : 'is-normal']"
      >
        <slot name="after-label" />
      </div>
    </template>

    <CollapseTransition>
      <q-banner
        dense
        rounded
        v-if="errorMessage"
        class="col-auto bg-white text-red-9 q-pa-none"
      >
        <small><b>{{ errorMessage }}</b></small>
      </q-banner>
    </CollapseTransition>
  </div>
</template>

<script>
import { CollapseTransition } from '@ivanv/vue-collapse-transition'

export default {
  components: {
    CollapseTransition
  },

  props: {
    label: {
      type: String
    },

    state: {
      type: Object
    },

    isRequired: {
      type: Boolean,
      default: false
    },

    classLabel: {
      type: String,
      default: ''
    },

    classWrapper: {
      type: String,
      default: ''
    }
  },

  computed: {
    title () {
      const label = this.label || ''
      if (!label) return ''
      return this.isRequired ? `${label} *` : label
    },

    errorMessage () {
      return String((this.state && this.state.dirty) || this.error ? this.error || this.state.error || '' : '').trim()
    },

    hasError () {
      return Boolean(this.errorMessage)
    }
  },

  methods: {
    handleClick () {
      this.$emit('on-click')
    }
  }
}
</script>

<style lang="sass">
  .mural-field
    &.my-field-number
      .myInput
        .q-field__control-container
          input
            text-align: right

    .label
      position: relative
      font-style: normal
      font-size: 13px
      line-height: 18px
      padding-right: 10px
      padding-bottom: 2px

    .has-error
      font-weight: 800
      color: #D32F2F
      .myInput
        .q-field__control:before
          border: 1px solid #D32F2F !important

        .q-field__control
          &:hover
            &:before
              border: 1px solid #D32F2F
              transition: none

      .myInput
        .q-field__control
          &:hover
            &:before
              border: 1px solid #486e8e
              transition: none

    .label-form
      color: #212121
      font-weight: 600

    .label-filter
      color: #263238
      font-weight: normal

    .myInput.q-field--readonly
      .q-field__control
        &:before
          border-style: solid !important

    .myInput
      .q-field__control
        height: auto
        min-height: 36px
        padding: 0 4px 0 8px

        .q-field__control-container
          font-style: normal
          font-weight: normal
          font-size: 13px
          line-height: 18px
          color: rgba(38,50,56, 0.9)

        &:after
          border-width: 1px !important

      .q-field__control-container
        .q-field__native
          min-height: 0px !important
          padding-bottom: 2px

        .q-field__prefix
          line-height: inherit !important
          margin-bottom: 1px
          padding: 1px 1px 0 0

      .q-field__marginal
        height: 36px
        padding-bottom: 0px

      .q-field__native, .q-field__prefix, .q-field__suffix, .q-field__input
        padding-top: 2px

      .q-field__bottom
        padding: 3px 0

      .mdi:before, .mdi-set
        font-size: 0.9em
</style>
